.card {
  margin: 0 auto;
  float: none;
  margin-bottom: 10px;
  margin-top: 25px;
}

/* On screens that are 992px or less, set the background color to blue */

@media screen and (max-width: 899px) {
  .card {
    width: 90%;
  }
}

/* On screens that are 600px or less, set the background color to olive */

@media screen and (min-width: 900px) {
  .card {
    width: 800px;
  }
}

@page {
  size: auto;
  margin: 0;
}

@media print {
  .navbar {
    display: none;
  }
  .print {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .col-print-1 {
    width: 8%;
    float: left;
  }
  .col-print-2 {
    width: 16%;
    float: left;
  }
  .col-print-3 {
    width: 25%;
    float: left;
  }
  .col-print-4 {
    width: 33%;
    float: left;
  }
  .col-print-5 {
    width: 42%;
    float: left;
  }
  .col-print-6 {
    width: 50%;
    float: left;
  }
  .col-print-7 {
    width: 58%;
    float: left;
  }
  .col-print-8 {
    width: 66%;
    float: left;
  }
  .col-print-9 {
    width: 75%;
    float: left;
  }
  .col-print-10 {
    width: 83%;
    float: left;
  }
  .col-print-11 {
    width: 92%;
    float: left;
  }
  .col-print-12 {
    width: 100%;
    float: left;
  }
}